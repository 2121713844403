import { io } from 'socket.io-client'

const url = process.env.REACT_APP_URL_SOCKET || ''
const token = localStorage.getItem('userToken')
const socket = io(url, {
  extraHeaders: {
    authorization: `Bearer ${token}`,
  },
})

export default socket
