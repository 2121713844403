import React, { useCallback, useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Typography,
  Box,
  styled,
  Button,
  TablePagination,
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import Sidebar from '../../components/navbar/sidebar'
import 'react-datepicker/dist/react-datepicker.css'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { Vehicle, VehicleClass } from './models/vehicle'
import AddCarModal from './modals/add_user_modal'
import { CreateConsumerWithVehicleModel } from './models/create-consumer-vehicle'
import UpdateCarModal from './modals/update_consumer_vehicle'
import { apiCall } from '../../components/api/api'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#ff6600',
    color: theme.palette.common.white,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const VehiclePage: React.FC = () => {
  const [data, setData] = useState<Vehicle[]>([])
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
  const [vehicleClasses, setVehicleClasses] = useState<VehicleClass[]>([])
  const [vehicleEngines, setVehicleEngines] = useState([])

  const fetchData = useCallback(async () => {
    try {
      const response = await apiCall(
        `admin/consumer/vehicle?limit=${rowsPerPage}&page=${page + 1}&search=${encodeURIComponent('')}`,
        'get'
      )
      setData(response.items)
      setTotalRows(response.count)
    } catch (error) {
      console.error('Failed to fetch data:', error)
    }
  }, [page, rowsPerPage])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const fetchVehicleClassesAndEngines = useCallback(async () => {
    const token = localStorage.getItem('userToken')
    if (!token) return

    try {
      const classesResponse = await apiCall('vehicle-class', 'get')
      setVehicleClasses(classesResponse.items)

      const enginesResponse = await apiCall('vehicle-engine', 'get')
      setVehicleEngines(enginesResponse.items)
    } catch (error) {
      console.error('Failed to fetch vehicle classes and engines:', error)
    }
  }, [])

  useEffect(() => {
    fetchVehicleClassesAndEngines()
  }, [fetchVehicleClassesAndEngines])

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setSelectedVehicle(null)
  }

  const handleSaveNewVehicle = async (data: CreateConsumerWithVehicleModel) => {
    try {
      const token = localStorage.getItem('userToken')
      if (!token) {
        enqueueSnackbar('User token not found, please login again.', { variant: 'error' })
        return
      }

      if (data.vehicle.mileage) {
        data.vehicle.mileage = Number(data.vehicle.mileage)
      }

      await apiCall('admin/consumer', 'post', JSON.stringify(data))
      enqueueSnackbar('Car successfully added!', { variant: 'success' })
      fetchData()
    } catch (error) {
      let errorMessage = 'An unexpected error occurred'
      if (error instanceof Error) {
        errorMessage = error.message
      }
      console.error('Error saving car:', errorMessage)
      enqueueSnackbar(errorMessage, { variant: 'error' })
    } finally {
      handleCloseModal()
    }
  }

  const handleRowClick = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle)
  }

  const handleUpdateVehicle = async (data: Vehicle) => {
    try {
      const token = localStorage.getItem('userToken')
      if (!token) {
        enqueueSnackbar('User token not found, please login again.', { variant: 'error' })
        return
      }

      if (data.mileage) {
        data.mileage = Number(data.mileage)
      }

      await apiCall(
        `admin/consumer/vehicle/${data.id}`,
        'patch',
        JSON.stringify({ ...data, tire: data.tiresUpdateData })
      )

      enqueueSnackbar('Car successfully added!', { variant: 'success' })
      fetchData()
    } catch (error) {
      let errorMessage = 'An unexpected error occurred'
      if (error instanceof Error) {
        errorMessage = error.message
      }
      console.error('Error saving car:', errorMessage)
      enqueueSnackbar(errorMessage, { variant: 'error' })
    } finally {
      handleCloseModal()
    }
  }

  return (
    <Container
      maxWidth={false}
      style={{
        maxWidth: '90%',
        display: 'flex',
        marginLeft: '100px',
        marginBottom: '20px',
        marginTop: '20px',
      }}
    >
      <SnackbarProvider maxSnack={5}>
        <Sidebar />
      </SnackbarProvider>
      <Box style={{ flex: 1 }}>
        <Box
          style={{
            display: 'flex',
            flexDirection: "column",
            marginBottom: '20px',
          }}
        >
          <Typography variant="h4">
            Customer vehicles
          </Typography>
          <Button
            variant="contained"
            color="warning"
            onClick={handleOpenModal}
            sx={{
              mt: '10px',
              mb: '10px',
              backgroundColor: '#FF6600',
              width: '350px',
              fontWeight: 600,
            }}
          >
            Add customer vehicle
          </Button>
        </Box>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Customer Phone Number</StyledTableCell>
                  <StyledTableCell>Vehicle ID</StyledTableCell>
                  <StyledTableCell>VIN</StyledTableCell>
                  <StyledTableCell>Color</StyledTableCell>
                  <StyledTableCell>Tire size</StyledTableCell>
                  <StyledTableCell>Vehicle class</StyledTableCell>
                  <StyledTableCell>Vehicle engine hours</StyledTableCell>
                  <StyledTableCell>Vehicle engines</StyledTableCell>
                  <StyledTableCell>Mileage</StyledTableCell>
                  <StyledTableCell>Date of Purchase</StyledTableCell>
                  <StyledTableCell>Location</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((vehicle, index) => (
                  <StyledTableRow key={index} onClick={() => handleRowClick(vehicle)}>
                    <StyledTableCell>
                      {vehicle.consumer.phone_number ?? vehicle.consumer.email}
                    </StyledTableCell>
                    <StyledTableCell>{vehicle.name}</StyledTableCell>
                    <StyledTableCell>{vehicle.vin}</StyledTableCell>
                    <StyledTableCell>{vehicle.color}</StyledTableCell>
                    <StyledTableCell>
                      {vehicle.tires
                        .map(
                          (tire) => `${tire.position}: ${tire.width}/${tire.ratio}R${tire.diameter}`
                        )
                        .join(' ')}
                    </StyledTableCell>
                    <StyledTableCell>{vehicle.class?.name}</StyledTableCell>
                    <StyledTableCell>{vehicle.engine_hours}</StyledTableCell>
                    <StyledTableCell>{vehicle.engines.join(' ')}</StyledTableCell>
                    <StyledTableCell>{vehicle.mileage}</StyledTableCell>
                    <StyledTableCell>{vehicle.date_of_purchase}</StyledTableCell>
                    <StyledTableCell>{vehicle.location?.name}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalRows}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {selectedVehicle && (
          <UpdateCarModal
            open={!!selectedVehicle}
            onClose={() => setSelectedVehicle(null)}
            vehicle={selectedVehicle}
            onUpdate={handleUpdateVehicle}
            vehicleClasses={vehicleClasses}
            vehicleEngines={vehicleEngines}
          />
        )}
        <AddCarModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSave={handleSaveNewVehicle}
          vehicleClasses={vehicleClasses}
          vehicleEngines={vehicleEngines}
        />
      </Box>
    </Container>
  )
}

export default VehiclePage
