import { createTheme } from '@mui/material'

export const theme = createTheme({
  typography: {
    fontFamily: 'APP_FONT',
  },
  palette: {
    primary: {
      main: '#FF6600',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: '#f44336',
    },
  },
})
