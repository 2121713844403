
import { Carousel } from '@mantine/carousel'
import { Card, Image, ActionIcon } from '@mantine/core'
import { Modal } from '@mui/material'
import { IconArrowRight, IconArrowLeft } from '@tabler/icons-react';
import classes from './image_viewer.module.css';
import { Box } from '@mui/material';

export default function ImageViewerModal({
  open,
  onClose,
  imageUrls,
}: {
  open: boolean
  onClose: () => void
  imageUrls: {id: string, key: string, name: string}[]
}) {
  const generateFullImageUrl = (path: string) => (`https://joincarma-images.s3.amazonaws.com/${path}`)

  return (
    <Modal open={open} onClose={onClose}>
      <Card
        style={{
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '1400px',
          maxHeight: '95vh',
          borderRadius: '16px'
        }}
      >
        <Carousel
          classNames={classes}
          withIndicators
          height={'100%'}
          nextControlIcon={<ActionIcon variant="filled" color="orange" aria-label="nextIcon" size="lg" radius="lg"><IconArrowRight style={{ width: '30px', height: '30px' }} stroke={2} /></ActionIcon>}
          previousControlIcon={<ActionIcon variant="filled" color="orange" aria-label="nextIcon" size="lg" radius="lg"><IconArrowLeft style={{ width: '30px', height: '30px' }} stroke={2} /></ActionIcon>}
        >
          {imageUrls.map((image) => (
            <Carousel.Slide>
              <Box display="flex" flexDirection="row" justifyContent="center">
                <Image radius="md" src={generateFullImageUrl(image.key)} h={'90vh'} w="auto" fit="contain"/>
              </Box>
            </Carousel.Slide>
          ))}
        </Carousel>
      </Card>
    </Modal>
  )
}