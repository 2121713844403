import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
  Backdrop,
  Typography,
  InputAdornment,
  IconButton,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Vehicle, VehicleClass } from '../models/vehicle'
import { ReactComponent as CalendarIcon } from '../../../assets/images/calendar.svg'
import { TireSize } from '../models/tire'
import { prepareTireItems } from '../helper'
import { apiCall } from '../../../components/api/api'

interface UpdateCarModalProps {
  open: boolean
  vehicle: Vehicle
  onClose: () => void
  onUpdate: (data: Vehicle) => void
  vehicleClasses: VehicleClass[]
  vehicleEngines: string[]
}

const UpdateCarModal: React.FC<UpdateCarModalProps> = ({
  open,
  vehicle,
  onClose,
  onUpdate,
  vehicleClasses,
  vehicleEngines,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Vehicle>({
    defaultValues: {
      name: vehicle.name,
      class_id: vehicle.class_id,
      engine_hours: vehicle.engine_hours,
      engines: vehicle.engines,
      mileage: vehicle.mileage,
      date_of_purchase: vehicle.date_of_purchase,
      tires: [],
    },
  })
  const [hasDifferentTires, setHasDifferentTires] = useState<boolean>(false)
  const [selectedVehicleClass, setSelectedVehicleClass] = useState<string>('')
  const [tireSizes, setTireSizes] = useState<TireSize[]>([])
  const [selectedVehicleEngines, setSelectedVehicleEngines] = useState<string[]>(vehicle.engines)

  useEffect(() => {
    if (open) {
      const fetchTireSizeList = async () => {
        setIsLoading(true)
        try {
          const response = await apiCall(
            `admin/consumer/vehicle/tire-sizes-by-vin?vin=${vehicle.vin}`,
            'get'
          )
          setTireSizes(response.items)
        } catch (error) {
          console.error('An error occurred while fetching tire sizes.', error)
        } finally {
          setIsLoading(false)
        }
      }

      fetchTireSizeList()
    }
  }, [open, vehicle.vin])

  if (!vehicle) return null

  const onSubmit = async (data: Vehicle) => {
    setIsLoading(true)
    try {
      let dataToUpdate: Partial<Vehicle> = {}

      dataToUpdate.name = data.name || undefined
      dataToUpdate.class_id = data.class_id || undefined
      dataToUpdate.engine_hours = data.engine_hours ? Number(data.engine_hours) : undefined
      dataToUpdate.engines = selectedVehicleEngines.length > 0 ? selectedVehicleEngines : undefined
      dataToUpdate.mileage = data.mileage || undefined
      dataToUpdate.date_of_purchase = data.date_of_purchase || undefined

      if (data.tires) {
        const tires = data.tires.toString()
        const isDifferent = hasDifferentTires
        dataToUpdate.tiresUpdateData = {
          has_different_tires: isDifferent,
          items: prepareTireItems(isDifferent, tires),
        }

        if (vehicle.id) {
          dataToUpdate = { id: vehicle.id, ...dataToUpdate }
        }
      }

      await onUpdate(dataToUpdate as Vehicle)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
      reset()
      onClose()
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{ style: { borderRadius: 12, width: 500, padding: 20 } }}
      >
        <DialogTitle className="title-font p-0">Update vehicle for a customer</DialogTitle>
        <DialogContent className="p-0">
          <Typography variant="subtitle1">
            <strong>Customer phone number or email</strong>
          </Typography>
          <Typography variant="body1" gutterBottom>
            {vehicle.consumer.phone_number}
          </Typography>
          <Typography variant="subtitle1">
            <strong>VIN</strong>
          </Typography>
          <Typography variant="body1" gutterBottom>
            {vehicle.vin}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Color:</strong>
          </Typography>
          <Typography variant="body1" gutterBottom>
            {vehicle.color}
          </Typography>
          <InputLabel className="label-font">Vehicle ID (optional)</InputLabel>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="text"
                fullWidth
                margin="dense"
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            )}
          />
          <InputLabel className="label-font" id="vehicle-class-label">
            Vehicle Class (optional)
          </InputLabel>
          <Controller
            name="class_id"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth margin="dense">
                <Select
                  {...field}
                  labelId="vehicle-class-label"
                  value={field.value ?? ''}
                  onChange={(event) => {
                    field.onChange(event)
                    setSelectedVehicleClass(event.target.value as string)
                  }}
                  displayEmpty
                >
                  {vehicleClasses.map((vehicleClass) => (
                    <MenuItem key={vehicleClass.id} value={vehicleClass.id}>
                      {`Class ${vehicleClass.name} - Weight: ${vehicleClass.weight.from} - ${vehicleClass.weight.to} lbs`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />

          <InputLabel className="label-font" id="vehicle-engines-label">
            Vehicle Engines (optional)
          </InputLabel>
          <FormControl fullWidth margin="dense">
            <Select
              name="engines"
              multiple
              value={selectedVehicleEngines}
              onChange={(event: any) => {
                const {
                  target: { value },
                } = event
                setSelectedVehicleEngines(typeof value === 'string' ? value.split(',') : value)
              }}
              renderValue={(selected) => (Array.isArray(selected) ? selected.join(', ') : '')}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                  },
                },
              }}
            >
              {vehicleEngines.map((engine) => (
                <MenuItem key={engine} value={engine}>
                  <Checkbox checked={selectedVehicleEngines.indexOf(engine) > -1} />
                  <ListItemText primary={engine} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <InputLabel className="label-font">Tire size (optional)</InputLabel>
          <Controller
            name="tires"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth margin="dense">
                <Controller
                  name="tires"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="tire-size-select-label"
                      displayEmpty
                      value={field.value}
                      onChange={(event) => field.onChange(event.target.value)}
                      MenuProps={{ style: { maxHeight: 220 } }}
                    >
                      {tireSizes?.map((tire, index) => {
                        const hasRearSize = tire.rear
                        return (
                          <MenuItem
                            key={index}
                            value={
                              hasRearSize
                                ? `${tire.front?.size}/${tire.rear?.size}`
                                : tire.front?.size
                            }
                          >
                            {hasRearSize
                              ? `Front: ${tire.front?.size} Rear: ${tire.rear?.size}`
                              : tire.front?.size}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            )}
          />
          <InputLabel className="label-font">Engine hours (optional)</InputLabel>
          <Controller
            name="engine_hours"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                fullWidth
                margin="dense"
                error={!!errors.engine_hours}
                helperText={errors.engine_hours?.message}
              />
            )}
          />
          <InputLabel className="label-font">Mileage (optional)</InputLabel>
          <Controller
            name="mileage"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                fullWidth
                margin="dense"
                error={!!errors.mileage}
                helperText={errors.mileage?.message}
              />
            )}
          />
          <InputLabel className="label-font">Date of purchase (optional)</InputLabel>
          <Controller
            name="date_of_purchase"
            control={control}
            render={({ field }) => (
              <DatePicker
                selected={field.value ? new Date(field.value) : null}
                onChange={(date) => field.onChange(date?.toISOString())}
                placeholderText="Date of Purchase"
                customInput={
                  <TextField
                    {...field}
                    fullWidth
                    margin="dense"
                    error={!!errors.date_of_purchase}
                    helperText={errors.date_of_purchase?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <CalendarIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    style={{ width: '100%', color: '#000' }}
                  />
                }
              />
            )}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button color="inherit" onClick={onClose} style={{ textTransform: 'none' }}>
            Cancel
          </Button>
          <Button
            color="warning"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            style={{ textTransform: 'none' }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {isLoading && (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  )
}

export default UpdateCarModal
