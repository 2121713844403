import React, { useState } from 'react'
import { Dialog, DialogContent, DialogTitle, TextField, Button } from '@mui/material'
import { NewActivationCode } from '../models/subscription'

interface NewSubscriptionModalProps {
  open: boolean
  onClose: () => void
  onSave: (newSubscription: NewSubscription) => void
}

export interface NewSubscription {
  code: string
  price: number
  period: string
}

const NewSubscriptionModal: React.FC<NewSubscriptionModalProps> = ({ open, onClose, onSave }) => {
  const [code, setActivationCode] = useState('')
  const [price, setPrice] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleSave = async () => {
    setIsLoading(true)
    try {
      const newSubscription: NewActivationCode = {
        code,
        price: Number(price),
        period: 'MONTHLY',
      }

      onSave(newSubscription)
    } catch (error) {
      console.error('Error saving new activation code:', error)
    } finally {
      setIsLoading(false)
      handleClose()
    }
  }

  const generateCode = () => {
    const part1 = Math.random().toString(36).substring(2, 10)
    const part2 = Math.random().toString(36).substring(2, 10)
    const code = (part1 + part2).substring(0, 16).toUpperCase()
    setActivationCode(code)
  }

  const handleClose = () => {
    resetModalFields()
    onClose()
  }

  const resetModalFields = () => {
    setActivationCode('')
    setPrice('')
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle fontWeight={600}>New activation code</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Activation code"
          fullWidth
          variant="outlined"
          value={code}
          onChange={(e) => setActivationCode(e.target.value)}
          InputProps={{
            endAdornment: (
              <Button onClick={generateCode} color="warning">
                Generate
              </Button>
            ),
          }}
        />
        <TextField
          margin="dense"
          label="Price per month"
          type="number"
          fullWidth
          variant="outlined"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          InputProps={{
            startAdornment: <span>$</span>,
          }}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Button
            onClick={handleClose}
            type="button"
            color="inherit"
            sx={{
              mt: 2,
              mb: 1,
              marginRight: '10px',
              fontFamily: 'Montserrat',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="warning"
            type="button"
            variant="contained"
            sx={{
              mt: 2,
              mb: 1,
              fontFamily: 'Montserrat',
            }}
          >
            Save
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default NewSubscriptionModal
