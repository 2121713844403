import React, { useCallback, useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Typography,
  Box,
  styled,
  TablePagination,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  Tooltip,
  InputAdornment,
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import Sidebar from '../../components/navbar/sidebar'
import 'react-datepicker/dist/react-datepicker.css'
import { Consumer } from './models/consumer'
import { SnackbarProvider, useSnackbar } from 'notistack'
import BlockIcon from '@mui/icons-material/Block'
import UnblockIcon from '@mui/icons-material/LockOpen'
import { ReactComponent as SearchIcon } from '../../assets/images/search.svg'
import { apiCall } from '../../components/api/api'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const ConsumerPage: React.FC = () => {
  const [data, setData] = useState<Consumer[]>([])
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
  const { enqueueSnackbar } = useSnackbar()
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [consumerToToggle, setConsumerToToggle] = useState<Consumer | null>(null)

  const fetchData = useCallback(async () => {
    try {
      const response = await apiCall(
        `admin/consumer?limit=${rowsPerPage}&page=${page + 1}&search=${encodeURIComponent(searchTerm)}`,
        'get'
      )
      setData(response.items)
      setTotalRows(response.count)
    } catch (error) {
      console.error('Failed to fetch data:', error)
    }
  }, [page, rowsPerPage, searchTerm])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleBlockUnblockClick = (consumer: Consumer) => {
    setConsumerToToggle(consumer)
    setOpenConfirmation(true)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false)
    setConsumerToToggle(null)
  }

  const confirmBlockUnblock = async () => {
    if (consumerToToggle) {
      await toggleConsumerBlockStatus(consumerToToggle)
    }
    handleCloseConfirmation()
  }

  const toggleConsumerBlockStatus = async (consumer: Consumer) => {
    try {
      setDeleteLoading(true)
      const endpoint = consumer.is_blocked ? 'unblock' : 'block'
      await apiCall(`admin/consumer/${consumer.id}/${endpoint}`, 'post')
      enqueueSnackbar(`Consumer successfully ${consumer.is_blocked ? 'unblocked' : 'blocked'}`, {
        variant: 'success',
      })
      const updatedData = data.map((item) =>
        item.id === consumer.id ? { ...item, is_blocked: !item.is_blocked } : item
      )
      setData(updatedData)
    } catch (error) {
      console.error(`Failed to ${consumer.is_blocked ? 'unblock' : 'block'} consumer:`, error)
      enqueueSnackbar(`Failed to ${consumer.is_blocked ? 'unblock' : 'block'} consumer`, {
        variant: 'error',
      })
    } finally {
      setDeleteLoading(false)
    }
  }

  return (
    <Container maxWidth="xl" style={{ display: 'flex' }}>
      <SnackbarProvider maxSnack={5}>
        <Sidebar />
      </SnackbarProvider>
      <Box
        style={{
          flex: 1,
          margin: '10px 30px',
          marginBottom: '50px',
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <Typography variant="h4" style={{ marginBottom: '20px' }}>
            Consumer List
          </Typography>
        </Box>
        <TextField
          label="Search for consumer"
          variant="outlined"
          color="warning"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            width: 400,
            marginBottom: '20px',
            backgroundColor: '#fff',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.23)',
              },
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'orange',
              },
            },
            '& .MuiInputLabel-root': {
              color: 'rgba(0, 0, 0, 0.6)',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
        />
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Phone Number</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((consumer) => (
                  <StyledTableRow key={consumer.id}>
                    <StyledTableCell>{consumer.name || 'N/A'}</StyledTableCell>
                    <StyledTableCell>{consumer.phone_number}</StyledTableCell>
                    <StyledTableCell>{consumer.email || 'N/A'}</StyledTableCell>
                    <StyledTableCell>{consumer.is_blocked ? 'BLOCKED' : 'ACTIVE'}</StyledTableCell>
                    <StyledTableCell>
                      <Tooltip title={consumer.is_blocked ? 'Unblock Consumer' : 'Block Consumer'}>
                        <IconButton
                          onClick={() => handleBlockUnblockClick(consumer)}
                          sx={{
                            color: consumer?.is_blocked ? '#FF6600' : '#D92D20',
                          }}
                          disabled={deleteLoading}
                          aria-label={consumer.is_blocked ? 'Unblock Consumer' : 'Block Consumer'}
                        >
                          {consumer.is_blocked ? <UnblockIcon /> : <BlockIcon />}
                        </IconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalRows}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <Dialog
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {consumerToToggle?.is_blocked ? 'Unblock' : 'Block'} the user?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to {consumerToToggle?.is_blocked ? 'unblock' : 'block'}{' '}
            {consumerToToggle?.name ?? consumerToToggle?.phone_number}?
          </DialogContentText>
          <DialogContentText>
            {consumerToToggle?.is_blocked
              ? 'Once unblocked, they will be able to use the mobile app again.'
              : 'They won’t be able to use the mobile app unless you unblock them.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            '& .MuiButton-root': {
              margin: '8px',
              width: 'calc(50% - 16px)',
              fontFamily: 'Montserrat',
            },
          }}
        >
          <Button
            onClick={handleCloseConfirmation}
            type="button"
            color="inherit"
            variant="outlined"
            sx={{
              border: '1px solid rgba(0, 0, 0, 0.23)',
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={confirmBlockUnblock}
            type="button"
            variant="contained"
            sx={{
              backgroundColor: consumerToToggle?.is_blocked ? '#FF6600' : '#D92D20',
              color: '#fff',
              '&:hover': {
                backgroundColor: consumerToToggle?.is_blocked ? '#CC5200' : '#AF1E1A',
              },
            }}
            autoFocus
          >
            {consumerToToggle?.is_blocked ? 'Unblock' : 'Block'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default ConsumerPage
