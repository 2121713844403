import { configureStore } from '@reduxjs/toolkit'
import { serviceApi } from "./api/services/service.api";

const rootReducer = {
  [serviceApi.reducerPath]: serviceApi.reducer,
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat([
      serviceApi.middleware
    ]),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
