import React, { useCallback, useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Typography,
  Box,
  styled,
  Button,
  TextField,
  TablePagination,
  InputAdornment,
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import Sidebar from '../../components/navbar/sidebar'
import 'react-datepicker/dist/react-datepicker.css'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { AddPromoCode, PromoCode } from './models/promo'
import PromoDetailsModal from './modals/promo-detail'
import NewPromoCodeModal from './modals/new-promo'
import { ReactComponent as SearchIcon } from '../../assets/images/search.svg'
import { apiCall } from '../../components/api/api'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const PromoPage: React.FC = () => {
  const [data, setData] = useState<PromoCode[]>([])
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedPromo, setSelectedPromo] = useState<PromoCode | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [totalRows, setTotalRows] = useState(0)

  const fetchData = useCallback(async () => {
    try {
      const response = await apiCall(
        `admin/offer-discount?limit=${rowsPerPage}&page=${page + 1}&search=${encodeURIComponent(searchTerm)}`,
        'get'
      )
      setData(response.items)
      setTotalRows(response.count)
    } catch (error) {
      console.error('Failed to fetch data:', error)
    }
  }, [page, rowsPerPage, searchTerm])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleDeletePromoCode = async () => {
    if (selectedPromo) {
      setDeleteLoading(true)
      try {
        const response = await apiCall(`admin/offer-discount/${selectedPromo.id}`, 'delete')
        fetchData()
        enqueueSnackbar('Promo Code successfully deleted!', { variant: 'success' })
      } catch (error) {
        console.error('Failed to delete promo code:', error)
        enqueueSnackbar('Failed to delete promo code:', { variant: 'error' })
      }
      setDeleteLoading(false)
      setSelectedPromo(null)
    }
  }

  const handleRowClick = (promoCode: PromoCode) => {
    setSelectedPromo(promoCode)
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setSelectedPromo(null)
  }

  const handleSaveNewPromo = async (newPromo: AddPromoCode) => {
    const token = localStorage.getItem('userToken')
    if (token) {
      try {
        const payload = {
          code: newPromo.code,
          ...(newPromo.included_provider_branch_ids.length > 0 && {
            included_provider_branch_ids: newPromo.included_provider_branch_ids.map(
              (el: any) => el.id
            ),
          }),
          ...(newPromo.providerId && { provider_id: newPromo.providerId }),
          discount: newPromo.discount.amount,
          amount_type: newPromo.discount.type,
          discount_type: newPromo.discount_type,
          amount: Number(newPromo.discount.amount),
          min_total_price: Number(newPromo.min_total_price),
          start_date: newPromo.activePeriod[0] ? newPromo.activePeriod[0].toISOString() : null,
          end_date: newPromo.activePeriod[1] ? newPromo.activePeriod[1].toISOString() : null,
        }

        await apiCall(`admin/offer-discount`, 'post', JSON.stringify(payload))
        fetchData()
        enqueueSnackbar('Promo Code successfully added!', { variant: 'success' })
      } catch (error) {
        console.error('Failed to save new promo:', error)
        enqueueSnackbar('Failed to save new promo:', { variant: 'error' })
      }
    }
  }

  return (
    <Container maxWidth="xl" style={{ display: 'flex' }}>
      <SnackbarProvider maxSnack={5}>
        <Sidebar />
      </SnackbarProvider>
      <Box style={{ flex: 1, margin: '10px 30px', marginBottom: '50px' }}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <Typography variant="h4" style={{ marginBottom: '20px' }}>
            Promo Codes
          </Typography>
          <Button variant="contained" color="warning" onClick={handleOpenModal}>
            Add new Promo Code
          </Button>
        </Box>
        <TextField
          label="Search for codes"
          variant="outlined"
          color="warning"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            width: 400,
            marginBottom: '20px',
            backgroundColor: '#fff',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.23)',
              },
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'orange',
              },
            },
            '& .MuiInputLabel-root': {
              color: 'rgba(0, 0, 0, 0.6)',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
        />
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow style={{ backgroundColor: '#f5f5f5' }}>
                  <StyledTableCell>Promo Code</StyledTableCell>
                  <StyledTableCell>Shop (Location)</StyledTableCell>
                  <StyledTableCell>Discount</StyledTableCell>
                  <StyledTableCell>Minimum total price for service</StyledTableCell>
                  <StyledTableCell>Active period</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row: PromoCode) => (
                  <StyledTableRow key={row.code} onClick={() => handleRowClick(row)}>
                    <StyledTableCell>{row.code}</StyledTableCell>
                    <StyledTableCell>
                      {row.provider?.name}{' '}
                      {row.branchNames.length > 0 ? '(' + row.branchNames.join(', ') + ')' : ''}
                    </StyledTableCell>
                    <StyledTableCell>{row.amount}</StyledTableCell>
                    <StyledTableCell>{row.min_total_price}</StyledTableCell>
                    <StyledTableCell>
                      {new Date(row.start_date).toLocaleDateString()} -{' '}
                      {new Date(row.end_date).toLocaleDateString()}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalRows}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      {selectedPromo && (
        <PromoDetailsModal
          open={!!selectedPromo}
          onClose={() => setSelectedPromo(null)}
          promoCode={selectedPromo}
          onDelete={handleDeletePromoCode}
          loading={deleteLoading}
        />
      )}
      <NewPromoCodeModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onSave={handleSaveNewPromo}
      />
    </Container>
  )
}

export default PromoPage
