import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Button,
  Modal,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Backdrop,
  CircularProgress,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import NewServiceModal from './new-service-modal'
import { MaintenanceResponse, Vehicle } from '../models/maintenance'
import { apiCall } from '../../../components/api/api'

interface VehicleMaintenanceModalProps {
  vehicle: Vehicle
  open: boolean
  onClose: () => void
}

const VehicleMaintenanceModal: React.FC<VehicleMaintenanceModalProps> = ({
  vehicle,
  open,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [servicesToDelete, setServicesToDelete] = useState<string[]>([])

  const [services, setServices] = useState<any[]>([])
  const [isNewServiceModalOpen, setIsNewServiceModalOpen] = useState(false)

  useEffect(() => {
    if (open) {
      fetchVehicleMaintenance(vehicle.id)
    }
  }, [vehicle, open])

  const fetchVehicleMaintenance = async (vehicleId: string) => {
    setIsLoading(true)
    try {
      const response = await apiCall(`admin/consumer/vehicle/${vehicleId}/maintenance`, 'get')
      setServices(response.items)
    } catch (error) {
      console.error('Failed to fetch maintenance data:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleOpenNewServiceModal = () => {
    setIsNewServiceModalOpen(true)
  }

  const handleCloseNewServiceModal = () => {
    setIsNewServiceModalOpen(false)
  }

  const handleSaveNewService = async (newService: any) => {
    const vehicleId = vehicle.id
    try {
      const response = await apiCall(
        `admin/consumer/vehicle/${vehicleId}/maintenance`,
        'post',
        JSON.stringify({
          service_id: newService.service_id,
          last_value: newService.last_value,
          interval: newService.interval,
          type: newService.type,
        })
      )

      setServices((prevServices) => [...prevServices, response])
      setIsNewServiceModalOpen(false)
    } catch (error) {
      console.error('Failed to save new service:', error)
    }
  }

  const handleDeleteService = async (serviceId: string) => {
    const vehicleId = vehicle.id
    try {
      await apiCall(`admin/consumer/vehicle/${vehicleId}/maintenance/${serviceId}`, 'delete')

      setServices((prevServices) => prevServices.filter((service) => service.id !== serviceId))
    } catch (error) {
      console.error('Error deleting service:', error)
    }
  }

  const markServiceForDeletion = (serviceId: string) => {
    setServices((currentServices) => currentServices.filter((service) => service.id !== serviceId))

    setServicesToDelete((prev) => [...prev, serviceId])
  }

  const handleSave = async () => {
    setIsDeleting(true)

    for (const serviceId of servicesToDelete) {
      await handleDeleteService(serviceId)
    }

    setIsDeleting(false)
    setServicesToDelete([])
    onClose()
  }

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" component="h2">
              Maintenance services
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography sx={{ mt: 2, mb: 2 }}>
              {vehicle.name
                ? `${vehicle.name} - ${vehicle.year} ${vehicle.make} ${vehicle.model}`
                : `${vehicle.year} ${vehicle.make} ${vehicle.model}`}
            </Typography>
            <Button
              variant="outlined"
              color="warning"
              startIcon={<AddIcon />}
              sx={{ mt: 2 }}
              onClick={handleOpenNewServiceModal}
            >
              New service
            </Button>
          </Box>
          <List>
            {services?.map((service, index) => (
              <React.Fragment key={index}>
                <ListItem
                  secondaryAction={
                    <React.Fragment>
                      <IconButton edge="end" aria-label="edit">
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => markServiceForDeletion(service.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </React.Fragment>
                  }
                >
                  <ListItemText
                    primary={service.service.parent.name}
                    secondary={
                      <>
                        <Typography variant="caption" color="text.secondary">
                          {service.service.name}
                        </Typography>
                        <br />
                        <Typography variant="caption" color="text.secondary">
                          Interval for service: {service.interval}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </React.Fragment>
            ))}
          </List>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="outlined" color="warning" sx={{ mr: 1 }} onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={handleSave}
              disabled={isDeleting || isLoading}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <NewServiceModal
        vehicle={vehicle}
        open={isNewServiceModalOpen}
        onClose={handleCloseNewServiceModal}
        onSave={handleSaveNewService}
      />
      {isLoading && (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  )
}

export default VehicleMaintenanceModal
