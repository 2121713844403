import React, { useState, ChangeEvent } from 'react';
import { Avatar as MUIavatar } from '@mui/material';

interface ClickableAvatarProps {
  defaultUrl: string;
  onFileSelected?: (file: File) => void;
}

export const Avatar: React.FC<ClickableAvatarProps> = ({ defaultUrl, onFileSelected }) => {
  const [imageUrl, setImageUrl] = useState<string>(defaultUrl);

  const handleAvatarClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/jpeg, image/png, image/svg+xml';
    fileInput.style.display = 'none';
    fileInput.onchange = (event: Event) => {
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const newUrl = e.target?.result as string;
          setImageUrl(newUrl);
          if (onFileSelected) {
            onFileSelected(file);
          }
        };
        reader.readAsDataURL(file);
      }
    };

    fileInput.click();
  };

  return (
    <MUIavatar
      alt="User Avatar"
      src={imageUrl}
      style={{ cursor: 'pointer' }}
      onClick={handleAvatarClick}
    />
  );
};
