export interface PromoCode {
  id?: string
  code: string
  included_provider_branch_ids?: string[]
  branchNames: string[]
  amount: number
  amount_type: string
  min_total_price: number
  start_date: string
  end_date: string
  provider?: {
    name?: string
  }
}

export const DISCOUNT_AMOUNT_TYPES = {
  AMOUNT: 'AMOUNT',
  PERCENTAGE: 'PERCENTAGE',
}

export interface AddPromoCode {
  id?: string
  code: string
  providerId: string
  included_provider_branch_ids: ProviderBranchOption[]
  discount: {
    amount: number
    type: string
  }
  discount_type: string
  min_total_price: number
  activePeriod: any
}

export interface ProviderBranchOption {
  id: string
  name: string
}
