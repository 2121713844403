import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CreateServiceRequest, ListServicesResponse, Service } from './service.types';
import { Pagination } from "../../types";

// Custom base query that includes the Authorization header
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem('userToken');

    // If we have a token set in localStorage, let's assume that we should be passing it.
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

// Define the service using the enhanced base query
export const serviceApi = createApi({
  reducerPath: 'serviceApi',
  baseQuery: baseQuery,
  tagTypes: ['Service'],
  endpoints: (builder) => ({
    listServices: builder.query<ListServicesResponse, Pagination & { search?: string }>({
      query: (params) => ({
        url: '/admin/service',
        params,
      }),
      providesTags: ['Service'],
    }),
    getService: builder.query<Service, string>({
      query: (id) => `/admin/service/${id}`,
      providesTags: ['Service'],
    }),
    createService: builder.mutation<Service, CreateServiceRequest>({
      query: (body) => ({
        url: '/admin/service',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Service'],
    }),
    updateService: builder.mutation<Service, CreateServiceRequest & { id: string }>({
      query: ({ id, ...rest }) => ({
        url: `/admin/service/${id}`,
        method: 'PUT',
        body: rest,
      }),
      invalidatesTags: ['Service'],
    }),
    deleteService: builder.mutation<void, string>({
      query: (id) => ({
        url: `/admin/service/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Service'],
    }),
    uploadServiceIcon: builder.mutation<void, { id: string, file: File }>({
      query: ({ id, file }) => {
        const formData = new FormData();
        formData.append('icon', file);

        return {
          url: `/admin/service/${id}/icon`,
          method: 'PATCH',
          body: formData,
        };
      },
      invalidatesTags: ['Service'],
    }),
  })
});

// Export hooks for usage in functional components
export const {
  useListServicesQuery,
  useGetServiceQuery,
  useCreateServiceMutation,
  useUpdateServiceMutation,
  useDeleteServiceMutation,
  useUploadServiceIconMutation,
} = serviceApi;
