import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  CssBaseline,
  TextField,
  Paper,
  Box,
  Grid,
  Typography,
  ThemeProvider,
  CircularProgress,
  Backdrop,
} from '@mui/material'
import loginImage from '../../../assets/images/login.png'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { theme } from '../../../theme'
import Logo from '../../../assets/images/app_icon.png'
import { apiCall } from '../../../components/api/api'

type LoginBase = {
  email: string
  password: string
}

const validationSchema = yup.object({
  email: yup.string().email('Enter a valid email').required('Email is required'),
  password: yup.string().required('Password is required'),
})

const Login = () => {
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const navigate = useNavigate()

  const loginUserApi = async (user: LoginBase) => {
    try {
      const response = await apiCall('/admin/auth/login', 'post', user)
      if (response && response.token) {
        return { userToken: response.token }
      } else {
        throw new Error('Invalid login')
      }
    } catch (error: any) {
      throw new Error(error.response?.data?.message || 'Error logging in.')
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setError(null)
      setLoading(true)
      try {
        const response = await loginUserApi(values)
        setLoading(false)
        if (response.userToken) {
          localStorage.setItem('userToken', response.userToken)
          navigate('/home')
        } else {
          setError('Error Message')
        }
      } catch (err) {
        setLoading(false)
        setError('Error logging in. Please try again.')
      }
    },
  })
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={6}
          md={6}
          sx={{
            backgroundImage: `url(${loginImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: '#FFF',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          sm={6}
          md={6}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              mx: 8,
              my: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              backgroundColor: 'white',
              borderRadius: '4px',
              padding: '6%',
            }}
          >
            <Box
              component="img"
              sx={{
                height: 48,
                width: 48,
                borderRadius: '10px',
                display: 'flex',
                mb: '32px',
              }}
              alt="The house from the offer."
              src={Logo}
            />
            <Typography
              component="h6"
              variant="h6"
              fontWeight="600"
              fontFamily={'Montserrat'}
              fontSize="30px"
              mb="18px"
            >
              Log In
            </Typography>
            {error && (
              <Typography fontSize="14px" color="error">
                {error}
              </Typography>
            )}
            <Box component="form" onSubmit={formik.handleSubmit}>
              <TextField
                label="Email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  style: { fontFamily: 'Montserrat' },
                }}
                autoFocus
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '412px',
                }}
              ></div>
              <TextField
                type="password"
                label="Password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  style: { fontFamily: 'Montserrat' },
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{
                  mt: 2,
                  mb: 1,
                  borderRadius: 10,
                  backgroundColor: theme.palette.primary.main,
                  color: 'white',
                  fontFamily: 'Montserrat',
                }}
              >
                Login
              </Button>
            </Box>
            {isLoading && (
              <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
                onClick={() => {}}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

export default Login
