import React, { useEffect, useState } from 'react'
import {
  Drawer,
  Button,
  Box,
  Typography,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Slider,
} from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { FilterValues } from '../vehicle/models/create-consumer-vehicle'
import { apiCall } from '../../components/api/api'

interface FilterDrawerProps {
  onApplyFilters: (filterValues: FilterValues) => void
}

const statusMapping = {
  REQUESTED: 'Requested',
  EXPIRED: 'Expired',
  ACCEPTED: 'Accepted',
  IN_PROGRESS: 'In Progress',
  CANCELLED: 'Cancelled',
  READY_FOR_PICKUP: 'Ready for Pickup',
  COMPLETED: 'Completed',
  PAYMENT_COMPLETED: 'Payment Completed',
}

const initialFilterValues: FilterValues = {
  metroAreas: {},
  requestedServices: {},
  status: {},
  quoteRange: [0, 1000],
}

const FilterDrawer: React.FC<FilterDrawerProps> = ({ onApplyFilters }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [filterValues, setFilterValues] = useState(initialFilterValues)
  const [showAllMetroAreas, setShowAllMetroAreas] = useState(false)

  const [allServices, setAllServices] = useState([])
  const [visibleServices, setVisibleServices] = useState([])
  const [showMoreServices, setShowMoreServices] = useState(false)

  let visibleStatuses = Object.entries(statusMapping).slice(0, 3)
  const [showMoreStatuses, setShowMoreStatuses] = useState(false)

  const fetchMetroAreas = async () => {
    try {
      const response = await apiCall('metro-area?is_active=true&limit=10&page=1', 'get')
      const metroAreasUpdate = response.items.reduce((acc: any, area: any) => {
        acc[area.name] = false
        return acc
      }, {})
      setFilterValues((prevValues) => ({
        ...prevValues,
        metroAreas: metroAreasUpdate,
      }))
    } catch (error) {
      console.error('Error fetching metro areas: ', error)
    }
  }

  const fetchServices = async () => {
    try {
      const response = await apiCall('service?group=true&limit=10&page=1', 'get')
      setAllServices(response.items)
      setVisibleServices(response.items.slice(0, 5))
    } catch (error) {
      console.error('Error fetching services: ', error)
    }
  }

  const toggleShowMoreServices = () => {
    setShowMoreServices(!showMoreServices)
    setVisibleServices(!showMoreServices ? allServices : allServices.slice(0, 5))
  }

  const toggleShowMoreStatuses = () => {
    setShowMoreStatuses(!showMoreStatuses)
  }

  useEffect(() => {
    fetchMetroAreas()
    fetchServices()
  }, [])

  const toggleDrawer = (open: boolean) => () => {
    setIsDrawerOpen(open)
  }

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    category: keyof FilterValues
  ) => {
    setFilterValues({
      ...filterValues,
      [category]: {
        ...filterValues[category],
        [event.target.name]: event.target.checked,
      },
    })
  }

  const handleRangeChange = (event: Event, newValue: number | number[]) => {
    setFilterValues({
      ...filterValues,
      quoteRange: newValue as number[],
    })
  }

  const toggleShowAllMetroAreas = () => {
    setShowAllMetroAreas(!showAllMetroAreas)
  }

  const getVisibleMetroAreas = () => {
    const areas = Object.keys(filterValues.metroAreas)
    return showAllMetroAreas ? areas : areas.slice(0, 2)
  }

  const applyFilters = () => {
    onApplyFilters(filterValues)
    toggleDrawer(false)()
  }

  return (
    <React.Fragment>
      <Button startIcon={<FilterListIcon />} onClick={toggleDrawer(true)} color="warning">
        <Typography variant="h6">Filters</Typography>
      </Button>
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 'auto', padding: '20px' }} role="presentation">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Filters</Typography>
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography>Apply filters to table data.</Typography>
          <Typography sx={{ mt: 3 }}>Metro areas</Typography>
          <FormGroup>
            {getVisibleMetroAreas().map((area) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterValues.metroAreas[area]}
                    onChange={(e) => handleCheckboxChange(e, 'metroAreas')}
                    name={area}
                  />
                }
                label={area}
                key={area}
              />
            ))}
          </FormGroup>
          {Object.keys(filterValues.metroAreas).length > 2 && (
            <Button
              sx={{ display: 'block', textTransform: 'none' }}
              color="warning"
              onClick={toggleShowAllMetroAreas}
            >
              {showAllMetroAreas ? 'Show Less' : 'Show More'}
            </Button>
          )}
          <Typography sx={{ mt: 3, mb: 2 }}>Requested services</Typography>
          <TextField
            placeholder="Search"
            variant="outlined"
            size="small"
            InputProps={{ endAdornment: <SearchIcon /> }}
          />
          <FormGroup>
            {visibleServices.map((service: any, index) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterValues.requestedServices[service.name] || false}
                    onChange={(e) => handleCheckboxChange(e, 'requestedServices')}
                    name={service.name}
                  />
                }
                label={service.name}
                key={service.id}
              />
            ))}
            {allServices.length > 5 && (
              <Button
                sx={{ display: 'block', textTransform: 'none' }}
                color="warning"
                onClick={toggleShowMoreServices}
              >
                {showMoreServices ? 'Show Less' : 'Show More'}
              </Button>
            )}
          </FormGroup>
          <Typography sx={{ mt: 3 }}>Order status</Typography>
          <FormGroup>
            {showMoreStatuses
              ? Object.entries(statusMapping).map(([key, value]) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterValues.status[key] || false}
                        onChange={(e) => handleCheckboxChange(e, 'status')}
                        name={key}
                      />
                    }
                    label={value}
                    key={key}
                  />
                ))
              : visibleStatuses.map(([key, value]) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterValues.status[key] || false}
                        onChange={(e) => handleCheckboxChange(e, 'status')}
                        name={key}
                      />
                    }
                    label={value}
                    key={key}
                  />
                ))}
          </FormGroup>

          {statusMapping && (
            <Button
              sx={{ display: 'block', textTransform: 'none' }}
              color="warning"
              onClick={toggleShowMoreStatuses}
            >
              {showMoreStatuses ? 'Show Less' : 'Show More'}
            </Button>
          )}
          <Typography sx={{ mt: 3 }}>Range of winning quote:</Typography>
          <Box sx={{ paddingX: 2, marginTop: 2 }}>
            <Slider
              value={filterValues.quoteRange}
              onChange={handleRangeChange}
              valueLabelDisplay="auto"
              min={0}
              max={1000}
              sx={{ color: '#ed6c02' }}
            />
          </Box>

          <Button
            variant="outlined"
            color="inherit"
            onClick={toggleDrawer(false)}
            sx={{
              mt: 1,
              mb: 1,
              marginRight: '10px',
              fontWeight: 600,
              width: '100px'
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={applyFilters}
            sx={{
              mt: 1,
              mb: 1,
              marginRight: '10px',
              fontWeight: 600,
              width: '100px'
            }}
          >
            Apply
          </Button>
        </Box>
      </Drawer>
    </React.Fragment>
  )
}

export default FilterDrawer
