import React, { useCallback, useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Typography,
  Box,
  styled,
  Button,
  TablePagination,
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import Sidebar from '../../components/navbar/sidebar'
import 'react-datepicker/dist/react-datepicker.css'
import { Subscription, NewActivationCode } from './models/subscription'
import SubscriptionDetailsModal from './modals/subscription-detail'
import NewSubscriptionModal from './modals/new-subscription'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { apiCall } from '../../components/api/api'

const formatDate = (date: Date) => {
  if (!date) return 'N/A'

  const d = new Date(date)
  return d.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#ff6600',
    color: theme.palette.common.white,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const SubscriptionPage: React.FC = () => {
  const [data, setData] = useState<Subscription[]>([])
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [selectedSubscription, setSelectedSubscription] = useState<Subscription | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [totalRows, setTotalRows] = useState(0)

  const fetchData = useCallback(async () => {
    const token = localStorage.getItem('userToken')
    if (token) {
      try {
        const response = await apiCall(
          `admin/subscription?limit=${rowsPerPage}&page=${page + 1}&search=${encodeURIComponent('')}`,
          'get'
        )
        setData(response.items)
        setTotalRows(response.total)
      } catch (error) {
        console.error('Failed to fetch data:', error)
      }
    }
  }, [page, rowsPerPage])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setSelectedSubscription(null)
  }

  const handleSaveNewSubscription = async (newSubscription: NewActivationCode) => {
    const token = localStorage.getItem('userToken')
    if (token) {
      try {
        const payload = {
          code: newSubscription.code,
          price: newSubscription.price,
          period: newSubscription.period,
        }

        await apiCall('admin/subscription/activation-code', 'post', JSON.stringify(payload))
        fetchData()
        enqueueSnackbar('Activation Code successfully added!', { variant: 'success' })
      } catch (error) {
        console.error('Failed to save new activation code', error)
        enqueueSnackbar('Failed to save new activation code:', { variant: 'error' })
      }
    }
  }

  const handleRowClick = (subscription: Subscription) => {
    setSelectedSubscription(subscription)
  }

  const handleDeleteSubscription = async (subscriptionId: string) => {
    const token = localStorage.getItem('userToken')
    if (token) {
      try {
        setDeleteLoading(true)
        await apiCall(`admin/subscription/${subscriptionId}`, 'delete')
        const updatedData = data.filter((subscription) => subscription.id !== subscriptionId)
        setData(updatedData)
        setTotalRows((prev) => prev - 1)
        enqueueSnackbar('Subscription successfully deleted', { variant: 'success' })
      } catch (error) {
        console.error('Failed to delete subscription:', error)
        enqueueSnackbar('Failed to delete subscription', { variant: 'error' })
      } finally {
        setDeleteLoading(false)
        handleCloseModal()
      }
    }
  }

  return (
    <Container
      maxWidth={false}
      style={{
        maxWidth: '90%',
        display: 'flex',
        marginLeft: '100px',
        marginBottom: '20px',
        marginTop: '20px',
      }}
    >
      <SnackbarProvider maxSnack={5}>
        <Sidebar />
      </SnackbarProvider>
      <Box style={{ flex: 1 }}>
        <Box
          style={{
            display: 'flex',
            flexDirection: "column",
            marginBottom: '20px',
          }}
        >
          <Typography variant="h4">
            Business Subscriptions
          </Typography>
          <Button
            variant="contained"
            color="warning"
            onClick={handleOpenModal}
            sx={{
              mt: '10px',
              mb: '10px',
              backgroundColor: '#FF6600',
              width: '350px',
              fontWeight: 600,
            }}
          >
            Create activation code
          </Button>
        </Box>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Activation code</StyledTableCell>
                  <StyledTableCell>Business name</StyledTableCell>
                  <StyledTableCell>Price per month</StyledTableCell>
                  <StyledTableCell>Next charge date</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((subscription: Subscription) => (
                  <React.Fragment key={subscription.id}>
                    <StyledTableRow onClick={() => handleRowClick(subscription)}>
                      <StyledTableCell>{subscription.code}</StyledTableCell>
                      <StyledTableCell>
                        {subscription.ConsumerSubscription &&
                        subscription.ConsumerSubscription.length > 0
                          ? subscription.ConsumerSubscription.map((sub) => sub.business_name).join(
                              ', '
                            )
                          : 'No Consumers'}
                      </StyledTableCell>
                      <StyledTableCell>{subscription.price}</StyledTableCell>
                      <StyledTableCell>
                        {subscription.ConsumerSubscription &&
                        subscription.ConsumerSubscription.length > 0
                          ? subscription.ConsumerSubscription.map((sub) =>
                              formatDate(sub.next_charge_date)
                            ).join(', ')
                          : 'No Consumers'}
                      </StyledTableCell>
                    </StyledTableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalRows}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        {selectedSubscription && (
          <SubscriptionDetailsModal
            open={!!selectedSubscription}
            onClose={() => setSelectedSubscription(null)}
            subscription={selectedSubscription}
            onDelete={handleDeleteSubscription}
            loading={deleteLoading}
          />
        )}
        <NewSubscriptionModal
          open={isModalOpen}
          onClose={handleCloseModal}
          onSave={handleSaveNewSubscription}
        />
      </Box>
    </Container>
  )
}

export default SubscriptionPage
