import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Box,
  IconButton,
  DialogActions,
  Backdrop,
  CircularProgress,
} from '@mui/material'
import { Subscription } from '../models/subscription'
import CloseIcon from '@mui/icons-material/Close'
import { ReactComponent as DeleteIcon } from '../../../assets/images/delete.svg'

interface SubscriptionDetailsModalProps {
  open: boolean
  onClose: () => void
  subscription: Subscription
  onDelete: (subscriptionId: string) => void
  loading: boolean
}

const SubscriptionDetailsModal: React.FC<SubscriptionDetailsModalProps> = ({
  open,
  onClose,
  subscription,
  onDelete,
  loading,
}) => {
  if (!subscription) return null

  const handleDelete = () => {
    onDelete(subscription.id)
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center" fontWeight={600}>
            Subscription Details
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box py={1}>
            <Typography variant="subtitle1" fontWeight={600} gutterBottom>
              Activation code:
            </Typography>
            <Typography variant="body2" color={'#667085'} gutterBottom>
              {subscription.code}
            </Typography>
          </Box>
          <Box py={1}>
            <Typography variant="subtitle1" fontWeight={600} gutterBottom>
              Business name:
            </Typography>
            <Typography variant="body2" color={'#667085'} gutterBottom>
              {subscription.ConsumerSubscription.map((sub) => sub.business_name)?.join(', ')}
            </Typography>
          </Box>
          <Box py={1}>
            <Typography variant="subtitle1" fontWeight={600} gutterBottom>
              Price per month:
            </Typography>
            <Typography variant="body2" color={'#667085'} gutterBottom>
              {subscription.price}
            </Typography>
          </Box>
          <Box py={1}>
            <Typography variant="subtitle1" fontWeight={600} gutterBottom>
              Next charge date:
            </Typography>
            <Typography variant="body2" color={'#667085'} gutterBottom>
              {subscription.ConsumerSubscription.map((sub) => sub.next_charge_date)?.join(', ')}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={handleDelete} color="error" startIcon={<DeleteIcon />}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {loading && (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          onClick={() => {}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  )
}

export default SubscriptionDetailsModal
