import React, { useState } from 'react'
import { Typography, List, ListItem, ListItemText, Button, Box, Divider } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import VehicleMaintenanceModal from './vehicle-maintenance-modal'
import { Customer, Vehicle } from '../models/maintenance'

interface CustomerDetailsPanelProps {
  customer: Customer
  onClose: () => void
}

const CustomerDetailsPanel: React.FC<CustomerDetailsPanelProps> = ({ customer, onClose }) => {
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null)

  const handleVehicleClick = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle)
  }

  const handleCloseModal = () => {
    setSelectedVehicle(null)
  }

  return (
    <Box
      sx={{
        maxWidth: '400px',
        flexGrow: 0,
        padding: '20px',
        backgroundColor: 'white',
        boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        Customer Details
      </Typography>
      <Divider />
      <List disablePadding>
        <ListItem>
          <ListItemText
            primary="Customer phone number or/and email"
            secondary={`${customer.phone_number ?? 'N/A'} \n ${customer.email ?? ''}`}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary="Name" secondary={customer.name} />
        </ListItem>
        <Divider />
      </List>
      <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
        Vehicles
      </Typography>
      <List disablePadding>
        {customer.vehicles?.map((vehicle) => (
          <React.Fragment key={vehicle.id}>
            <ListItem
              sx={{
                py: 1,
                px: 2,
                my: 1,
                backgroundColor: '#f5f5f5',
                borderRadius: '8px',
                boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              secondaryAction={
                <Button onClick={() => handleVehicleClick(vehicle)}>
                  <ArrowForwardIosIcon color="warning" />
                </Button>
              }
            >
              <ListItemText
                primary={
                  vehicle.name
                    ? `${vehicle.name} - ${vehicle.year} ${vehicle.make} ${vehicle.model}`
                    : `${vehicle.year} ${vehicle.make} ${vehicle.model}`
                }
                secondary={`Current mileage: ${vehicle.mileage} miles`}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </React.Fragment>
        ))}
      </List>

      <Button onClick={onClose} color="warning" variant="text" sx={{ mt: 2 }}>
        Add maintenance service for several vehicles
      </Button>
      {selectedVehicle && (
        <VehicleMaintenanceModal
          vehicle={selectedVehicle}
          open={!!selectedVehicle}
          onClose={handleCloseModal}
        />
      )}
    </Box>
  )
}

export default CustomerDetailsPanel
