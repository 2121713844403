import Decimal from 'decimal.js'

export const formatCurrency = (amount: number | string | Decimal) => {
  const parsedAmount = amount instanceof Decimal ? amount.toNumber() : Number(amount)

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(parsedAmount)
}
