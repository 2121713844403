import React from 'react'
import { Box, IconButton } from '@mui/material'

type FileItem = {
  id: string
  name: string
  url: string
}

type FilePreviewProps = {
  files: FileItem[]
}

const videoMimeTypes: { [key: string]: string } = {
  mp4: 'video/mp4',
  webm: 'video/webm',
  ogg: 'video/ogg',
  mov: 'video/mp4',
}

const style = {
  mediaContainer: {
    width: '200px',
    overflow: 'hidden',
  },
  media: {
    maxWidth: '200px',
    maxHeight: '200px',
  },
}

const FilePreview: React.FC<FilePreviewProps> = ({ files }) => {
  const getVideoMimeType = (fileName: string) => {
    const extension = fileName.split('.').pop()?.toLowerCase()
    return extension && videoMimeTypes[extension] ? videoMimeTypes[extension] : 'video/mp4'
  }

  return (
    <>
      {files.map((file) => {
        const isVideo = Object.keys(videoMimeTypes).some((ext) =>
          file.name.toLowerCase().endsWith(ext)
        )
        const media = isVideo ? (
          <video style={style.media} controls>
            <source src={file.url} type={getVideoMimeType(file.name)} />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img src={file.url} alt={file.name} style={style.media} />
        )

        return (
          <Box key={file.id} mr={1} sx={{ borderRadius: '4px' }} style={style.mediaContainer}>
            <IconButton onClick={() => window.open(file.url, '_blank')} sx={{ padding: 0 }}>
              {media}
            </IconButton>
          </Box>
        )
      })}
    </>
  )
}

export default FilePreview
