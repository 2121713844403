import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  Backdrop,
  InputAdornment,
  IconButton,
  Checkbox,
  ListItemText,
} from '@mui/material'
import { CreateConsumerWithVehicleModel } from '../models/create-consumer-vehicle'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import { ReactComponent as CalendarIcon } from '../../../assets/images/calendar.svg'
import { Tire, TireSize } from '../models/tire'
import { VehicleClass } from '../models/vehicle'
import { prepareTireItems } from '../helper'
import { apiCall } from '../../../components/api/api'

interface AddCarModalProps {
  isOpen: boolean
  onClose: () => void
  onSave: (data: CreateConsumerWithVehicleModel) => void
  vehicleClasses: VehicleClass[]
  vehicleEngines: string[]
}

const AddCarModal: React.FC<AddCarModalProps> = ({
  isOpen,
  onClose,
  onSave,
  vehicleClasses,
  vehicleEngines,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<CreateConsumerWithVehicleModel>()
  const [tires, setTires] = useState<Tire[]>([])
  const [hasDifferentTires, setHasDifferentTires] = useState<boolean>(false)
  const [selectedVehicleClass, setSelectedVehicleClass] = useState<string>('')
  const [tireSizes, setTireSizes] = useState<TireSize[]>([])
  const [selectedVehicleEngines, setSelectedVehicleEngines] = useState<string[]>([])

  const handleFetchTireSizes = async () => {
    const vin = watch('vehicle.vin')
    if (!vin) return
    setIsLoading(true)
    try {
      const response = await apiCall(
        `admin/consumer/vehicle/tire-sizes-by-vin?vin=${vin}`,
        'get'
      )
      setTireSizes(response.items)
    } catch (error) {
      console.error('An error occurred while fetching tire sizes.', error)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmit: SubmitHandler<CreateConsumerWithVehicleModel> = async (data) => {
    setIsLoading(true)
    try {
      let dataToAdd: CreateConsumerWithVehicleModel = {
        credential: data.credential,
        vehicle: {
          name: data.vehicle.name,
          vin: data.vehicle.vin,
          color: data.vehicle.color,
          class_id: data.vehicle.class_id,
          engine_hours: data.vehicle.engine_hours ? Number(data.vehicle.engine_hours) : undefined,
          mileage: data.vehicle.mileage,
          date_of_purchase: data.vehicle.date_of_purchase,
          engines: selectedVehicleEngines.length > 0 ? selectedVehicleEngines : undefined,
        },
      }

      if (data.vehicle.tires) {
        const tires = data.vehicle.tires.toString()
        const isDifferent = hasDifferentTires
        dataToAdd.vehicle.tire = {
          has_different_tires: isDifferent,
          items: prepareTireItems(isDifferent, tires),
        }
      }

      await onSave(dataToAdd)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
      reset()
      onClose()
    }
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        PaperProps={{ style: { borderRadius: 12, width: 400, padding: 12 } }}
      >
        <DialogTitle>Add car for a customer</DialogTitle>
        <DialogContent>
          <InputLabel className="label-font">Phone number or email</InputLabel>
          <Controller
            name="credential"
            control={control}
            rules={{ required: 'Phone number or email is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="dense"
                placeholder="Customer phone number or email"
                type="text"
                fullWidth
                variant="outlined"
                error={!!errors.credential}
                helperText={errors.credential?.message}
              />
            )}
          />
          <InputLabel className="label-font">VIN</InputLabel>
          <Controller
            name="vehicle.vin"
            control={control}
            rules={{ required: 'VIN is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="dense"
                placeholder="VIN"
                type="text"
                fullWidth
                variant="outlined"
                onBlur={() => {
                  field.onBlur()
                  handleFetchTireSizes()
                }}
                error={!!errors.vehicle?.vin}
                helperText={errors.vehicle?.vin?.message}
              />
            )}
          />
          <InputLabel className="label-font">Color</InputLabel>
          <Controller
            name="vehicle.color"
            control={control}
            rules={{ required: 'Color is required' }}
            render={({ field }) => (
              <FormControl fullWidth margin="dense">
                <Select
                  {...field}
                  labelId="color-select-label"
                  placeholder="Color"
                  fullWidth
                  variant="outlined"
                  error={!!errors.vehicle?.color}
                  MenuProps={{ style: { maxHeight: 220 } }}
                >
                  <MenuItem value="Black">Black</MenuItem>
                  <MenuItem value="Blue">Blue</MenuItem>
                  <MenuItem value="Brown">Brown</MenuItem>
                  <MenuItem value="Burgundy">Burgundy</MenuItem>
                  <MenuItem value="Gold">Gold</MenuItem>
                  <MenuItem value="Gray">Gray</MenuItem>
                  <MenuItem value="Orange">Orange</MenuItem>
                  <MenuItem value="Purple">Purple</MenuItem>
                  <MenuItem value="Red">Red</MenuItem>
                  <MenuItem value="Silver">Silver</MenuItem>
                  <MenuItem value="Tan">Tan</MenuItem>
                  <MenuItem value="Teal">Teal</MenuItem>
                  <MenuItem value="White">White</MenuItem>
                  <MenuItem value="Yellow">Yellow</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <InputLabel className="label-font">Vehicle ID</InputLabel>
          <Controller
            name="vehicle.name"
            control={control}
            rules={{ required: 'Vehicle ID is required' }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="dense"
                placeholder="Vehicle ID"
                type="text"
                fullWidth
                variant="outlined"
                error={!!errors.vehicle?.name}
                helperText={errors.vehicle?.name?.message}
              />
            )}
          />
          <InputLabel className="label-font">Vehicle Class</InputLabel>
          <Controller
            name="vehicle.class_id"
            control={control}
            rules={{ required: 'Vehicle class is required' }}
            render={({ field }) => (
              <FormControl fullWidth margin="dense">
                <Select
                  {...field}
                  labelId="vehicle-class-label"
                  value={field.value ?? ''}
                  onChange={(event) => {
                    field.onChange(event)
                    setSelectedVehicleClass(event.target.value as string)
                  }}
                  displayEmpty
                >
                  {vehicleClasses.map((vehicleClass) => (
                    <MenuItem key={vehicleClass.id} value={vehicleClass.id}>
                      {`Class ${vehicleClass.name} - Weight: ${vehicleClass.weight.from} - ${vehicleClass.weight.to} lbs`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <InputLabel className="label-font" id="vehicle-engines-label">
            Vehicle Engines (optional)
          </InputLabel>
          <FormControl fullWidth margin="dense">
            <Select
              name="vehicle.engines"
              multiple
              value={selectedVehicleEngines}
              onChange={(event: any) => {
                const {
                  target: { value },
                } = event
                setSelectedVehicleEngines(typeof value === 'string' ? value.split(',') : value)
              }}
              renderValue={(selected) => (Array.isArray(selected) ? selected.join(', ') : '')}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                  },
                },
              }}
            >
              {vehicleEngines.map((engine) => (
                <MenuItem key={engine} value={engine}>
                  <Checkbox checked={selectedVehicleEngines.indexOf(engine) > -1} />
                  <ListItemText primary={engine} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <InputLabel className="label-font">Tire size (optional)</InputLabel>
          <Controller
            name="vehicle.tires"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth margin="dense">
                <Controller
                  name="vehicle.tires"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="tire-size-select-label"
                      displayEmpty
                      value={field.value}
                      onChange={(event) => field.onChange(event.target.value)}
                      MenuProps={{ style: { maxHeight: 220 } }}
                    >
                      {tireSizes?.map((tire, index) => {
                        const hasRearSize = tire.rear
                        return (
                          <MenuItem
                            key={index}
                            value={
                              hasRearSize
                                ? `${tire.front?.size}/${tire.rear?.size}`
                                : tire.front?.size
                            }
                          >
                            {hasRearSize
                              ? `Front: ${tire.front?.size} Rear: ${tire.rear?.size}`
                              : tire.front?.size}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  )}
                />
              </FormControl>
            )}
          />
          <InputLabel className="label-font">Engine hours (optional)</InputLabel>
          <Controller
            name="vehicle.engine_hours"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                fullWidth
                margin="dense"
                error={!!errors.vehicle?.engine_hours}
                helperText={errors.vehicle?.engine_hours?.message}
              />
            )}
          />
          <InputLabel className="label-font">Mileage (optional)</InputLabel>
          <Controller
            name="vehicle.mileage"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                fullWidth
                margin="dense"
                error={!!errors.vehicle?.mileage}
                helperText={errors.vehicle?.mileage?.message}
              />
            )}
          />
          <InputLabel className="label-font">Date of purchase (optional)</InputLabel>
          <Controller
            name="vehicle.date_of_purchase"
            control={control}
            render={({ field }) => (
              <DatePicker
                selected={field.value ? new Date(field.value) : null}
                onChange={(date) => field.onChange(date?.toISOString())}
                placeholderText="Date of Purchase"
                customInput={
                  <TextField
                    {...field}
                    fullWidth
                    margin="dense"
                    error={!!errors.vehicle?.date_of_purchase}
                    helperText={errors.vehicle?.date_of_purchase?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <CalendarIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    style={{ width: '100%', color: '#000' }}
                  />
                }
              />
            )}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button color="inherit" onClick={onClose} style={{ textTransform: 'none' }}>
            Cancel
          </Button>
          <Button
            color="warning"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            style={{ textTransform: 'none' }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {isLoading && (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  )
}

export default AddCarModal
