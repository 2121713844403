import React, { useCallback, useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Typography,
  Box,
  styled,
  TablePagination,
  TextField,
  InputAdornment,
} from '@mui/material'
import TableCell, { TableCellProps, tableCellClasses } from '@mui/material/TableCell'
import Sidebar from '../../components/navbar/sidebar'
import 'react-datepicker/dist/react-datepicker.css'
import { SnackbarProvider } from 'notistack'
import CustomerDetailsPanel from './modals/customer-detail-panel'
import { Customer, Vehicle } from './models/maintenance'
import { ReactComponent as SearchIcon } from '../../assets/images/search.svg'
import { apiCall } from '../../components/api/api'

interface StyledTableCellProps extends TableCellProps {
  wrapText?: boolean
}
const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'wrapText',
})<StyledTableCellProps>(({ theme, wrapText }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: wrapText ? 'normal' : 'nowrap',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

const MaintenancePage: React.FC = () => {
  const [data, setData] = useState([])
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [totalRows, setTotalRows] = useState(0)
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null)
  const [isModalOpen, setModalOpen] = useState(false)

  const handleRowClick = useCallback((customer: Customer) => {
    setSelectedCustomer(customer)
    setModalOpen(true)
  }, [])

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const fetchData = useCallback(async () => {
    try {
      const response = await apiCall(
        `admin/consumer?limit=${rowsPerPage}&page=${page + 1}&search=${encodeURIComponent(searchTerm)}`,
        'get'
      )
      setData(response.items)
      setTotalRows(response.count)
    } catch (error) {
      console.error('Failed to fetch data:', error)
    }
  }, [page, rowsPerPage, searchTerm])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Container
      maxWidth={false}
      style={{
        maxWidth: '90%',
        display: 'flex',
        marginLeft: '100px',
        marginBottom: '30px',
        marginTop: '30px',
      }}
    >
      <SnackbarProvider maxSnack={5}>
        <Sidebar />
      </SnackbarProvider>
      <Box style={{ flex: 1 }}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <Typography variant="h4" style={{ marginBottom: '20px' }}>
            Vehicle maintenance
          </Typography>
        </Box>
        <TextField
          label="Search for customers"
          variant="outlined"
          color="warning"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            width: 400,
            marginBottom: '20px',
            backgroundColor: '#fff',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.23)',
              },
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'orange',
              },
            },
            '& .MuiInputLabel-root': {
              color: 'rgba(0, 0, 0, 0.6)',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
        />
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Customer phone number or/and email</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Vehicles</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((customer: Customer, index) => (
                  <StyledTableRow key={index} onClick={() => handleRowClick(customer)}>
                    <StyledTableCell>{customer.phone_number ?? customer.email}</StyledTableCell>
                    <StyledTableCell>{customer.name}</StyledTableCell>
                    <StyledTableCell wrapText={true}>
                      {customer.vehicles
                        ?.map((vehicle: Vehicle) =>
                          vehicle.name
                            ? `${vehicle.name} - ${vehicle.year} ${vehicle.make} ${vehicle.model}`
                            : `${vehicle.year} ${vehicle.make} ${vehicle.model}`
                        )
                        .join('; ') ?? ''}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalRows}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      {isModalOpen && selectedCustomer && (
        <CustomerDetailsPanel customer={selectedCustomer} onClose={handleCloseModal} />
      )}
    </Container>
  )
}

export default MaintenancePage
