import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
  Backdrop,
  CircularProgress,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { PromoCode } from '../models/promo'
import { ReactComponent as DeleteIcon } from '../../../assets/images/delete.svg'

interface PromoDetailsModalProps {
  open: boolean
  onClose: () => void
  promoCode: PromoCode
  onDelete: () => void
  loading: boolean
}

const PromoDetailsModal: React.FC<PromoDetailsModalProps> = ({
  open,
  onClose,
  promoCode,
  onDelete,
  loading,
}) => {
  if (!promoCode) return null

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center" fontWeight={600}>
            Promo Code
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box py={1}>
            <Typography variant="subtitle1" fontWeight={600} gutterBottom>
              Promo Code:
            </Typography>
            <Typography variant="body2" color={'#667085'} gutterBottom>
              {promoCode.code}
            </Typography>
          </Box>
          <Box py={1}>
            <Typography variant="subtitle1" fontWeight={600} gutterBottom>
              Shop:
            </Typography>
            <Typography variant="body2" color={'#667085'} gutterBottom>
              {promoCode.branchNames?.join(', ')}
            </Typography>
          </Box>
          <Box py={1}>
            <Typography variant="subtitle1" fontWeight={600} gutterBottom>
              Discount:
            </Typography>
            <Typography variant="body2" color={'#667085'} gutterBottom>
              {promoCode.amount}
            </Typography>
          </Box>
          <Box py={1}>
            <Typography variant="subtitle1" fontWeight={600} gutterBottom>
              Minimum total price for service:
            </Typography>
            <Typography variant="body2" color={'#667085'} gutterBottom>
              {promoCode.min_total_price}
            </Typography>
          </Box>
          <Box py={1}>
            <Typography variant="subtitle1" fontWeight={600} gutterBottom>
              Active period:
            </Typography>
            <Typography variant="body2" color={'#667085'} gutterBottom>
              {' '}
              {new Date(promoCode.start_date).toLocaleDateString()} -{' '}
              {new Date(promoCode.end_date).toLocaleDateString()}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={onDelete} color="error" startIcon={<DeleteIcon />}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {loading && (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          onClick={() => {}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  )
}

export default PromoDetailsModal
