import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  DialogActions,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  Chip,
  Checkbox,
  Divider,
  createTheme,
  ThemeProvider,
  OutlinedInput,
  InputAdornment,
  Box,
  Typography,
  Backdrop,
  CircularProgress,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { AddPromoCode, DISCOUNT_AMOUNT_TYPES } from '../models/promo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateRangePicker, SingleInputDateRangeField } from '@mui/x-date-pickers-pro'
import { apiCall } from '../../../components/api/api'

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF6600',
    },
  },
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          '&:focus': {
            borderColor: '#FF6600',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#FF6600',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
  },
})

interface NewPromoCodeModalProps {
  open: boolean
  onClose: () => void
  onSave: (promo: AddPromoCode) => void
}

const NewPromoCodeModal: React.FC<NewPromoCodeModalProps> = ({ open, onClose, onSave }) => {
  const [newPromoCode, setNewPromoCode] = useState<any>({
    code: '',
    providerId: '',
    included_provider_branch_ids: [],
    discount: {
      amount: 0,
      type: DISCOUNT_AMOUNT_TYPES.AMOUNT,
    },
    discount_type: 'CARMA',
    min_total_price: 0,
    activePeriod: [null, null] as [Date | null, Date | null],
  })
  const [providerBranchOptions, setProviderBranchOptions] = useState([])
  const [providerOptions, setProviderOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const isAllSelected =
    providerBranchOptions.length > 0 &&
    newPromoCode.included_provider_branch_ids.length === providerBranchOptions.length

  const isFormValid = () => {
    return (
      newPromoCode.code.trim() !== '' &&
      newPromoCode.discount.amount > 0 &&
      newPromoCode.min_total_price >= 0 &&
      newPromoCode.activePeriod[0] !== null &&
      newPromoCode.activePeriod[1] !== null
    )
  }

  useEffect(() => {
    const fetchProviderBranches = async () => {
      try {
        const response = await apiCall('admin/provider', 'get')
        setProviderOptions(
          response?.map((provider: any) => ({
            id: provider.id,
            name: provider.name,
          }))
        )
      } catch (error) {
        console.error('Failed to fetch provider branches:', error)
      }
    }

    fetchProviderBranches()
  }, [])

  useEffect(() => {
    if (newPromoCode.providerId) {
      const fetchProviderBranches = async () => {
        try {
          const response = await apiCall(`admin/provider/branche/${newPromoCode.providerId}`, 'get')
          setProviderBranchOptions(
            response?.map((branch: any) => ({
              id: branch.id,
              name: branch.name,
            }))
          )
        } catch (error) {
          console.error('Failed to fetch provider branches:', error)
        }
      }

      fetchProviderBranches()
    }
  }, [newPromoCode.providerId])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    if (name === 'min_total_price') {
      let formattedValue = value

      if (!value.includes('.') && value.startsWith('0')) {
        formattedValue = value.replace(/^0+(\d)/, '$1')
      }

      setNewPromoCode({ ...newPromoCode, [name]: formattedValue })
    } else {
      setNewPromoCode({ ...newPromoCode, [name]: value })
    }
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setNewPromoCode({
        ...newPromoCode,
        included_provider_branch_ids: providerBranchOptions.map((option: any) => ({
          id: option.id,
          name: option.name,
        })),
      })
    } else {
      setNewPromoCode({
        ...newPromoCode,
        included_provider_branch_ids: [],
      })
    }
  }

  const handleSelectChange = (
    event: SelectChangeEvent<typeof newPromoCode.included_provider_branch_ids>
  ) => {
    const { value } = event.target
    let updatedSelections = []

    if (value.includes('all')) {
      updatedSelections = isAllSelected ? [] : [...providerBranchOptions]
    } else {
      updatedSelections = providerBranchOptions.filter((option: any) => value.includes(option.id))
    }

    setNewPromoCode({
      ...newPromoCode,
      included_provider_branch_ids: updatedSelections,
    })
  }

  const handleSave = async () => {
    const updatedPromoCode = {
      ...newPromoCode,
      discount_type: newPromoCode.providerId ? 'SHOP' : 'CARMA',
    }
    setIsLoading(true)
    try {
      await onSave(updatedPromoCode)
      setNewPromoCode({
        code: '',
        providerId: '',
        included_provider_branch_ids: [],
        discount: {
          amount: 0,
          type: DISCOUNT_AMOUNT_TYPES.AMOUNT,
        },
        discount_type: 'CARMA',
        min_total_price: 0,
        activePeriod: [null, null],
      })
    } catch (error) {
    } finally {
      setIsLoading(false)
      onClose()
    }
    onClose()
  }

  const handleDiscountChange = (event: any) => {
    const { name, value } = event.target

    let formattedValue = value

    if (!value.includes('.') && value.startsWith('0')) {
      formattedValue = value.replace(/^0+(\d)/, '$1')
    }

    setNewPromoCode({
      ...newPromoCode,
      discount: {
        ...newPromoCode.discount,
        [name]: formattedValue ?? value,
      },
    })
  }

  const handleDiscountTypeToggle = () => {
    const newType =
      newPromoCode.discount.type === DISCOUNT_AMOUNT_TYPES.AMOUNT
        ? DISCOUNT_AMOUNT_TYPES.PERCENTAGE
        : DISCOUNT_AMOUNT_TYPES.AMOUNT
    setNewPromoCode({
      ...newPromoCode,
      discount: {
        ...newPromoCode.discount,
        type: newType,
      },
    })
  }

  const handleActivePeriodChange = (dateRange: [Date | null, Date | null]) => {
    setNewPromoCode({ ...newPromoCode, activePeriod: dateRange })
  }

  const renderValue = (selected: any[]) => (
    <>
      {selected.map((selectedItem) => {
        const selectedShop = providerBranchOptions.find(
          (option: any) => option.id === selectedItem
        ) ?? { id: '', name: '' }
        return (
          <Chip
            key={selectedShop.id}
            label={selectedShop.name}
            onDelete={(event) => handleDeleteProviderBranch(event, selectedShop.id)}
            onMouseDown={(event) => event.stopPropagation()}
          />
        )
      })}
    </>
  )

  const handleDeleteProviderBranch = (event: React.MouseEvent, branchIdToDelete: string) => {
    event.stopPropagation()
    setNewPromoCode((prev: any) => ({
      ...prev,
      included_provider_branch_ids: prev.included_provider_branch_ids.filter(
        (item: any) => item.id !== branchIdToDelete
      ),
    }))
  }

  const handleProviderChange = (event: SelectChangeEvent) => {
    setNewPromoCode({ ...newPromoCode, providerId: event.target.value as string })
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
          <DialogTitle>
            New Promo Code
            <IconButton
              onClick={onClose}
              style={{ position: 'absolute', right: '10px', top: '10px' }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box margin="dense">
              <Typography variant="subtitle1" gutterBottom>
                Promo Code
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                name="code"
                value={newPromoCode.code}
                onChange={handleChange}
              />
            </Box>
            <Box margin="dense">
              <Typography variant="subtitle1" gutterBottom>
                Provider
              </Typography>
              <FormControl fullWidth>
                <Select
                  labelId="provider-id-label"
                  id="provider-id-select"
                  name="providerId"
                  value={newPromoCode.providerId}
                  onChange={handleProviderChange}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 224,
                        width: '250px',
                      },
                    },
                  }}
                >
                  {providerOptions.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box margin="dense">
              <Typography variant="subtitle1" gutterBottom>
                Locations (optional)
              </Typography>
              <FormControl fullWidth>
                <Select
                  labelId="branch-ids-label"
                  id="branch-ids-select"
                  multiple
                  name="included_provider_branch_ids"
                  value={newPromoCode.included_provider_branch_ids.map((item: any) => item.id)}
                  onChange={handleSelectChange}
                  renderValue={renderValue}
                  disabled={!newPromoCode.providerId}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 224,
                        width: '250px',
                      },
                    },
                  }}
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={isAllSelected}
                      indeterminate={
                        newPromoCode.included_provider_branch_ids.length > 0 && !isAllSelected
                      }
                      onChange={handleSelectAll}
                    />
                    Select All
                  </MenuItem>
                  <Divider />
                  {providerBranchOptions.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      <Checkbox
                        checked={newPromoCode.included_provider_branch_ids.some(
                          (selectedItem: any) => selectedItem.id === option.id
                        )}
                      />
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box margin="dense">
              <Typography variant="subtitle1" gutterBottom>
                Discount
              </Typography>
              <FormControl fullWidth variant="outlined">
                <OutlinedInput
                  id="discount-amount"
                  name="amount"
                  type="number"
                  value={newPromoCode.discount.amount}
                  onChange={handleDiscountChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle amount type"
                        onClick={handleDiscountTypeToggle}
                        edge="end"
                      >
                        {newPromoCode.discount.type === DISCOUNT_AMOUNT_TYPES.AMOUNT ? '$' : '%'}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
            <Box margin="dense">
              <Typography variant="subtitle1" gutterBottom>
                Minimum total price for service
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                name="min_total_price"
                type="number"
                value={newPromoCode.min_total_price}
                onChange={handleChange}
              />
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                Active period
              </Typography>
              <DateRangePicker
                slots={{ field: SingleInputDateRangeField }}
                value={newPromoCode.activePeriod}
                onChange={handleActivePeriodChange}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              onClick={handleSave}
              color="warning"
              variant="contained"
              disabled={!isFormValid()}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        {isLoading && (
          <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default NewPromoCodeModal
