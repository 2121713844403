import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Button,
  Modal,
  TextField,
  MenuItem,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Vehicle } from '../models/maintenance'
import { apiCall } from '../../../components/api/api'

interface NewServiceModalProps {
  open: boolean
  vehicle: Vehicle
  onClose: () => void
  onSave: (service: any) => void
}

const NewServiceModal: React.FC<NewServiceModalProps> = ({ open, onClose, onSave, vehicle }) => {
  const [service, setService] = useState({
    service_id: '',
    type: '',
    description: '',
    interval: '',
    last_value: '',
  })
  const [serviceList, setServiceList] = useState<any[]>([])
  const [serviceTypes, setServiceTypes] = useState([])
  const [intervalType, setIntervalType] = useState('MILEAGE')

  const handleSave = () => {
    let serviceToSave = {}

    if (serviceTypes.length === 0) {
      const selectedService = serviceList.find((s) => s.name === service.type)
      serviceToSave = {
        service_id: selectedService ? selectedService.id : '',
        last_value: service.last_value,
        interval: parseInt(service.interval, 10) || 0,
        type: intervalType,
      }
    } else {
      serviceToSave = {
        service_id: service.service_id,
        last_value: service.last_value,
        interval: parseInt(service.interval, 10) || 0,
        type: intervalType,
      }
    }

    onSave(serviceToSave)
    onClose()
    setService({ service_id: '', type: '', description: '', interval: '', last_value: '' })
  }

  useEffect(() => {
    const fetchServiceList = async () => {
      try {
        const response = await apiCall(
          `service?group=true&is_maintenance=true`,
          'get'
        )
        const dataList: any[] = []
        response.items?.map((service: any) =>
          service.children?.map((child: any) => dataList.push(child))
        )

        setServiceList(dataList)
      } catch (error) {
        console.error('Failed to fetch service list:', error)
      }
    }

    fetchServiceList()
  }, [])

  const handleServiceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedType = event.target.value as string
    setService({ ...service, type: selectedType })

    const selectedService: any = serviceList.find((s: any) => s.name === selectedType)
    if (selectedService && selectedService.children) {
      setServiceTypes(selectedService.children)
    } else {
      setServiceTypes([])
    }
  }

  const handleServiceTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedServiceId = event.target.value as string
    const selectedType = serviceTypes.find((type: any) => type.id === selectedServiceId)

    if (selectedType) {
      setService({ ...service, service_id: selectedServiceId })
    }
  }

  const handleIntervalTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIntervalType(event.target.value as string)
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 'auto',
          maxWidth: 'calc(100% - 40px)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" component="h2">
            New maintenance service
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography>
            {vehicle.name
              ? `${vehicle.name} - ${vehicle.year} ${vehicle.make} ${vehicle.model}`
              : `${vehicle.year} ${vehicle.make} ${vehicle.model}`}
          </Typography>
        </Box>

        <Box sx={{ mt: 2 }}>
          <TextField
            select
            fullWidth
            label="Service"
            value={service.type}
            onChange={handleServiceChange}
            sx={{ mb: 2 }}
          >
            {serviceList?.map((service: any) => (
              <MenuItem key={service.id} value={service.name}>
                {service.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            fullWidth
            label="Service type"
            value={service.service_id}
            onChange={handleServiceTypeChange}
            sx={{ mb: 2 }}
            disabled={serviceTypes.length === 0}
          >
            {serviceTypes?.map((type: any) => (
              <MenuItem key={type.id} value={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </TextField>

          <RadioGroup
            row
            value={intervalType}
            onChange={handleIntervalTypeChange}
            sx={{ mb: 2 }}
            color="warning"
          >
            <FormControlLabel value="MILEAGE" control={<Radio />} label="Mileage" />
            <FormControlLabel value="ENGINE_HOURS" control={<Radio />} label="Engine hours" />
            <FormControlLabel value="DAYS" control={<Radio />} label="Days" />
          </RadioGroup>

          {intervalType === 'MILEAGE' && (
            <TextField
              fullWidth
              label="Last service completion mileage"
              value={service.last_value}
              onChange={(e) => setService({ ...service, last_value: e.target.value })}
              sx={{ mb: 2 }}
            />
          )}
          {intervalType === 'ENGINE_HOURS' && (
            <TextField
              fullWidth
              label="Last service completion engine hours"
              value={service.last_value}
              onChange={(e) => setService({ ...service, last_value: e.target.value })}
              sx={{ mb: 2 }}
            />
          )}
          {intervalType === 'DAYS' && (
            <TextField
              fullWidth
              label="Last service completion date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={service.last_value}
              onChange={(e) => setService({ ...service, last_value: e.target.value })}
              sx={{ mb: 2 }}
            />
          )}

          <TextField
            fullWidth
            label="Interval for service (in miles)"
            value={service.interval}
            onChange={(e) => setService({ ...service, interval: e.target.value })}
            sx={{ mb: 2 }}
          />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button variant="outlined" color="warning" sx={{ mr: 1 }} onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="warning" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default NewServiceModal
