import { Tire } from './models/tire'

export function formatTireSizes(tires: any) {
  const frontTire = tires.find((t: any) => t.position === 'front')
  const rearTire = tires.find((t: any) => t.position === 'rear')

  const formatTire = (tire: Tire) => (tire ? `${tire.width}/${tire.ratio}R${tire.diameter}` : '')

  const frontTireString = frontTire ? `Front: ${formatTire(frontTire)}` : ''
  const rearTireString = rearTire ? `Rear: ${formatTire(rearTire)}` : frontTireString

  return `${frontTireString}; ${rearTireString}`.trim()
}
export function prepareTireItems(hasDifferentTires: boolean, tires: any): any[] {
  let items: any[] = []

  if (!tires) return items
  const tireParts = tires.split('/')
  const parseTire = (tire: string) => {
    const [width, rest] = tire.split('/')
    const [ratio, diameter] = rest.split('R')
    return {
      width: parseInt(width, 10),
      ratio: parseInt(ratio, 10),
      diameter: parseInt(diameter, 10),
      size: tire,
    }
  }

  if (hasDifferentTires) {
    const front = parseTire(tireParts[0] + '/' + tireParts[1])
    const rear = parseTire(tireParts[2] + '/' + tireParts[3])
    items.push({ position: 'FRONT', ...front })
    items.push({ position: 'REAR', ...rear })
  } else {
    const front = parseTire(tireParts[0] + '/' + tireParts[1])
    items.push({ position: 'FRONT', ...front })
    if (tireParts.length === 4) {
      items.push({ position: 'REAR', ...front })
    }
  }

  return items
}
