import React, { useState } from 'react'
import { Modal, Box, Typography, Button, TextField, Container } from '@mui/material'

interface FeedbackForShopModalProps {
  open: boolean
  onClose: () => void
  onConfirm: (instruction: string) => void
}

const FeedbackForShopModal: React.FC<FeedbackForShopModalProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  const [instruction, setInstruction] = useState('')
  const [isLoading, setIsLoading] = React.useState(false)

  const handleConfirm = () => {
    setIsLoading(true)
    onConfirm(instruction)
  }

  const handleClose = () => {
    setIsLoading(false)
    onClose()
  }

  const isFormValid = () => {
    return instruction.trim().length > 0
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="feedback-modal-title"
      aria-describedby="feedback-modal-description"
    >
      <Container
        maxWidth="sm"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          outline: 'none',
          maxHeight: '90vh',
          overflowY: 'auto',
        }}
      >
        <Typography id="feedback-modal-title" variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>
          Instruction to fix the invoice
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 2 }}>
          Instructions for the service center:
        </Typography>
        <TextField
          fullWidth
          multiline
          placeholder="Write instructions here..."
          value={instruction}
          onChange={(e) => setInstruction(e.target.value)}
          variant="outlined"
          color="warning"
          rows={8}
          sx={{
            mb: 4,
            '& .MuiOutlinedInput-root': {
              borderRadius: 1,
            },
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            sx={{
              width: '45%',
              bgcolor: 'grey.300',
              color: 'text.primary',
              ':hover': {
                bgcolor: 'grey.400',
              },
            }}
            onClick={handleClose}
          >
            Back
          </Button>
          <Button
            sx={{
              width: '45%',
              bgcolor: '#FF6600',
              ':hover': {
                bgcolor: '#FF6600',
              },
            }}
            variant="contained"
            onClick={handleConfirm}
            disabled={!isFormValid() || isLoading}
          >
            Send to shop
          </Button>
        </Box>
      </Container>
    </Modal>
  )
}

export default FeedbackForShopModal
